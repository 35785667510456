.amenities{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-column-gap: 35px;
  grid-row-gap: 1vw;
  @include mq($until: md) {
    grid-template-columns: repeat(4,1fr);
    grid-column-gap: 15px;
    grid-row-gap: 30px;
  }
  @include mq($until: xs) {
    grid-template-columns: repeat(3,1fr);
  }
}

.amenity{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  svg{
    fill: $color-main;
    height: 55px;
    width: auto;
  }
  &__name{
    margin-top: 10px;
    text-align: center;
  }
}