div#flags {
    position: absolute;
    left: 420px;

    img {
        width: 30px;
        opacity: 0.5;
        transition: all .3s;
        &:hover {
            opacity: 1;
        }
    }

    .active-lang  {
        img {
            opacity: 1;
        }
    }
}

@include mq($until:xxl) {
    div#flags {
        left: 440px;
        img {
            width: 25px;
        }
    }
}    

@include mq($until:xl) {
    div#flags {
        left: 230px;
        img {
            width: 23px;
        }
    }
}

@include mq($until:lg) {  
    &__menuLink {
        font-size: .78rem;
    }

    div#flags {
        left: 60px;
        img {
            width: 29px;
            margin-left: 20px;
        }
    }
}

@include mq($until:md) {

    div#flags {
        right: 0;
        left: initial;
        top: 15%;
    }
}

@include mq($until:sm) {

    div#flags {
        top: 11%;
    }
}

@include mq($until:xs) {

    div#flags {

        img {
            margin-left: 8px;
            width: 23px;
        }
    }
}