.attraction-card{
    display: flex;
    justify-content: space-between;
   margin-bottom: 3vw;
   @include mq($until: md) {
       flex-direction: column;
       padding-bottom: 8vw;
   }

    &__head{
        color: $color-extra;
    }
    
        .attraction-card__content{
            width: 48%;
            padding-top: 3vw;
            box-flex-group: $color-main-light;
            @include mq($until: md) {
                width: 100%;
            }
        }
        .apartment-card__image{
            margin: 0;
        }
    .catalog-card__picture{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 48%;
        height: 34vw;
        @include mq($until: md) {
            width: 100%;
            height: 50vw;
        }
    }

}

