.button {
  color: $color-main-light;
  padding: .8vw 2.1vw;
  transition-duration: .3s;
  background-color: $color-extra-transparent;
  border-radius: 2px;
  display: inline-block;
  cursor: pointer;
  font-size: rem(21px);
  transition: all .4s ease-in-out;
  &:hover {

background-color: $color-main-transapernt;
    color:  $color-main-light;
  }


  
}