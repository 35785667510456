.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  // min-height: 100px;
  width: 100%;
  z-index: 50;
  font-size: rem(28px);
  @include mq($until: xl) {
    font-size: rem(21px);
  }
  @include mq($until: lg) {
    font-size: rem(18px);
  }
  &--fixed {
    position: fixed;
    width: 100%;
    transition: background-color .3s ease-in-out;

    &.sticky {
      background-color: lemonchiffon;
    }
  }
  &__top{
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-self: flex-start;
    align-items: center;
  }
  &--subpage {
    position: relative;
    background-color: $color-main;
    .header__logo {
      width: 210px;
      height: fit-content;
      @include mq($until: md) {
        width: 18vw;
        min-height: auto;
      }
      @include mq($until: sm) {
        width: 180px;
      }
      @include mq($until: xs) {
        width: 100px;
      }
    }
    .header__head-cllicker{
      span{
        color: $color-extra;
      }
      svg{
        fill:$color-extra;
      }
      &:hover {
        background-color: $color-extra;
        fill: $color-extra;
        border: 1px solid $color-extra;
        color: $color-main;
        svg{
          fill:$color-main;
        }
      }
    }
    .header__nav {
      padding-top: 1.4vw;
      @include mq($until: md) {
        padding-top: 13.4vw;
      }
      @include mq($until: sm) {
        padding-top:180px;
      }
      @include mq($until: xs) {
        padding-top:130px;
      }

      hr{
        margin: 8px 0 0 0;
      }
    }
    .header__menuList{

      li a{
        color: #fff;
        transition: color .4s ease-in-out;
        &:hover{
          color: $color-extra;
        }
       
      } 
    }
  }
  &--apartment{
    .header__nav{
    flex-direction: column;
    border-radius: 0 0 5px 5px;
    padding: 2vw 1.5vw 1vw 1vw ;
      padding-top: 1.4vw;
      @include mq($until: md) {
        padding-top: 13.4vw;
        background-color: $color-main;
      }
      @include mq($until: sm) {
        padding-top:180px;
      }
      @include mq($until: xs) {
        padding-top:130px;
  
      }
    }
    @include mq($until: xs) {
      position: relative;
      min-height: unset;
      background-color: $color-main;
    }
    .header__logo{
      height: 18vw;
      @include mq($until: xxl) {
        height: 16vw;
      }
      @include mq($until: lg) {
        height: 15vw;
      }
      @include mq($until: md) {
        width: 13vw;
        height: auto;
      }
      @include mq($until: sm) {
        width: 19vw;
      }
      @include mq($until: xs) {
        width: 100px;
        min-height: unset;
      }

    }
  }
  &__logo{
    position: relative;
    z-index: 2;
    background-color: $color-main-transapernt;
    width: 18vw;
    height: 13vw;
    align-self: flex-start;
    @include mq($until: xxl) {
      height: 16vw;
    }
    @include mq($until: lg) {
      width: 21vw;
    }
    @include mq($until: md) {
      height: 20vw;
    }
    @include mq($until: sm) {
      height: 19vw;
      width: 28vw;
    }
    @include mq($until: xs) {
      width: 44vw;
    }
  }
  &__photo{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-width: 100vw;
    @include mq($until: md){
      min-height: 80vw;
    }

    &--catalog{
      background-position: bottom -13vw center;
    }

    &--subpage{
      min-height: 55vh;
      @include mq($until: md){
          min-height: 50vw;
      }
      @include mq($until: xs){
          min-height: 33vh;
      }
      &:before{
        top: 0;
        left: 0;
      }
    }
  }

  &__title{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: #FFF;
    padding-top: 13px;
    z-index: 10;
    @include mq($until: lg) {
      font-size: rem(36px);
    }
    @include mq($until: xs){
      font-size: rem(28px);
    }
  }
  &__phone {
    margin-left: auto;
    font-size: rem(16px);
    color: $color-main;
    transition: all .4s ease-in-out;

    svg {
      max-width: 20px;
      height: auto;
      fill: $color-main;
      margin-right: 5px;
      margin-bottom: 3px;
    }

  }

  &__nav {
    padding-top: 3.4vw;
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    position: relative;
    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
    hr{
      background-color:$color-extra;
        margin: 13px 0 8px 0;
    }
    @include mq($until: xl) {
      hr{
        margin: 18px 0 13px 0;
      }
    }
    @include mq($until: md) {
      padding-top: 13.4vw;
      position: inherit;
    }
    @include mq($until: sm) {
      padding-top:180px;
    }
    @include mq($until: xs) {
      padding-top:210px;
    }
  }

  &__menuList {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    padding-top: 13px;
    &--fullscreen {
      flex-direction: column;
    }
  }

  &__menuItem {
    margin-left: 15px;
    padding: 0;
    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color:#fff;
    font-weight: 550;
    text-shadow: 2px 2px 1px rgba(25, 14, 30, 0.8);
    @include mq($until: md) {
      color: #ffffff;
      font-size: 3vw;
      font-weight: 500;
     margin-top: 21px; 
    }
    @include mq($until: sm) {
      color: #ffffff;
      font-size: 5vw;
    }
    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:active, &--active, &--open, &:hover {
      color: $color-extra;
    }
  }
  &__contact-buttons{
    text-align: right;
    @include mq($until: md) {
  text-align: center;
    }
    @include mq($until: sm) {
      display: flex;
      flex-direction: column;

      .header__phone {
        margin-top: 20px;
      }
    }
  }
  &__head-cllicker{
    margin-left: 8px;
      font-size: rem(16px);;
      border: 1px solid $color-extra;
      // background-color: $color-extra ;
      padding: .4vw .8vw  .4vw  .8vw;
      border-radius: 3px;
      transition: all .4s ease-in-out;
      font-weight: 600;
      text-transform: uppercase;
      span{
        color: $color-main;
        font-weight: 300;
      }
      @include mq($until: md) {
        span{
          color: $color-extra;
        }
      }
      @include mq($until: sm) {
        padding: 8px 13px  8px  13px;
      }
      svg{
        // fill: #fff;
        transition: all .4s ease-in-out;
        @include mq($until: md) {
         fill: $color-extra;
        }
      }
      &:hover {
        background-color: $color-extra;
        fill: $color-extra;
        border: 1px solid $color-extra;
        // color: $color-extra;
      }
  }
  &__outer-icons{
    display: flex;
    margin-left: auto;
    padding-right: 5vw;
    flex-direction: row;
    .header__head-cllicker {
      @include mq($until: xs) {
        padding: 8px;
      }
    }
    a{
      display: block;
    }
    .header__phone{
      svg{
        width: 30px;
        margin-right:0;
        margin-bottom:0;
      }

    }
    span{
      display: none;
    }
  }

}
