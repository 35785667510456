.list-item{
    display: inline-block;
    min-width: 500px;
    margin-top: 3vw;
    margin-bottom: 3vw;

    &:hover .list-item__content{
      background-color:  $color-extra-light;
    }
    &:hover .list-item__footer{
      background-color:  $color-extra-light;
    }
    @include mq($until: xs) {
      min-width: unset;
    }
    
    &__wrapper{
      width: 41vw;
      display: flex;
      min-height: 19vw;
      justify-content: space-between;
      @include mq($until: lg) {
        width: fit-content;
      }
      @include mq($until: xs) {
        flex-direction: column;
        width: 100%;
      }
    }
    &__imbox{
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include mq($until: xs) {
        width: 100%;
      }
    }
    &__image{
      min-height: 13vw;
      height: 200%;
      background-repeat: no-repeat;
      background-position: center;
      z-index: 1;
      overflow: hidden;
      background-size: cover;
      @include mq($until: lg) {
        min-height: 28vw;
      }
      @include mq($until: xs) {
        min-height: 50vw;
      }

    }
    &__badge{
      width: 80%;
      font-weight: 600;
      font-size: rem(18px);
      margin-top: auto;
      @include mq($until: xs) {
        margin-left: 4vw;
        width: 40%;
        margin-top: unset;
        color: $color-extra;
        // margin-top: 8px;
      }
    }
    &__clicker{
      padding-top: 1vw;
      align-self: flex-end;
      width: 90%;
      span{
      padding: 21px 34px 21px 0;
      font-style: italic;
      font-size: rem(15px);
      color: $color-extra;
      font-weight: normal;
      transition: all .4s ease-in-out;
        &:hover{
          color: $color-main;
        }
        &:hover hr{
          width: 5vw;
        }
        hr{
          background-color:  $color-extra;
          margin-top: 0;
          // margin-bottom: 13px;
          height: 1px;
          transition: all .4s ease-in-out;
        }
      }
      @include mq($until: lg) {
        padding: 5px 0 13px 0;
        hr{
          margin-top: 13px;
          margin-bottom: 0;
        }
      }
      @include mq($until: xs) {
        width: 55%;
        padding: 0;
        margin-left: auto;
      }

    }
    &__footer{
      width: 17vw;
      padding-top: 1vw;
      display: flex;
      margin-left: auto;
      background-color: $color-main-light;
      min-height: 5vw;
      flex-direction: column;
      align-items: center;
      height: 100%;
      transition: all .4s ease-in-out;
      @include mq($until: lg) {
        width: 42vw;
        padding-top: 21px;
      }
      @include mq($until: lg) {
        width: 39vw;
      }
      @include mq($until: sm) {
        width: 42vw;
      }
      @include mq($until: xs) {
        width: 100%;
        flex-direction: row;
        align-items: flex-start;
      }
    }
    &__header{
      font-weight: 600;
      margin-bottom:21px;
      font-size: rem(21px);
      @include mq($until: md) {
        margin-bottom:13px;
      }
    }
    &__content{
      width: 50%;
      padding: 2vw 2vw 2vw 2vw ;
      background-color: $color-main-light;
      align-self: flex-end;
      z-index: 0;
      min-height: 16vw;
      margin-top: 3vw;
      display: flex;
      flex-direction: column;
      transition: all .4s ease-in-out;
      @include mq($until: lg) {
        min-height: 34vw;
        padding: 3vw 3vw 2vw 3vw ;
      }
      @include mq($until: lg) {
        min-height: 37vw;
      }
      @include mq($until: sm) {
        min-height: 39vw;
        padding: 3vw 3vw 4vw 4vw ;
      }
      @include mq($until: xs) {
        width: 100%;
        margin-top: 0;
        padding: 0vw 4vw 4vw 4vw ;
        min-height: fit-content;
      }
    }
    &__description{
      @include mq($until: xxl) {
          line-height: 1.8;
          font-size: rem(15px);
      
      }
    }
}