// .attraction{
//     padding-top: 3vw;
//     text-align: center;


// }

.catalog-list{
    .attraction-card {
            &:nth-child(even){
            flex-direction: row-reverse;
            @include mq($until: md) {
                flex-direction: column;
                padding-bottom: 8vw;
            }         
        } 
    }
}