.top{
 
    #top-movie{
        video {
          max-width: 100vw; 
          width: 100vw;
          overflow: hidden;
          @include mq($until: sm) {
            max-width: unset; 
            width: 170vw;
          }
          @include mq($until: xs) {
            width: 190vw;
          }
        }
      }
    &__wrapper{
        position: absolute;
        display: flex;
        @include mq($until: xs) {
            flex-direction: column;
          }
    }

    &__site-tittle{
        align-self: flex-end;
        color: #fff;
        padding-left: 5vw;
        max-width: 38vw;
        font-size: 5vw;
        padding-bottom: .5vw;
        font-family: 'Garamond';
        line-height: .9;
        text-shadow: 0px 0px 55px rgba(0, 0, 0, 0.7);
        z-index: 3;
        margin-bottom: 0;
        @include mq($until: lg) {
            padding-bottom: 2.5vw;
            line-height: 1;
        }
        @include mq($until: md) {
            font-size:6vw;
        }
        @include mq($until: xs) {
            padding-left: 0;
            max-width: 40vw;
            font-size:7vw;
           margin-top:13px;
        }
    }
    &__hotblock {
        width: 18vw;
        background-color:$color-main-transapernt;
        margin-top: calc(100vw - 87vw);
        padding-top: 9vw;
        height: fit-content;
        z-index: 3;
        @include mq($until: xxl) {
            margin-top: calc(100vw - 84vw);
            padding-top: 5vw;
        }
        @include mq($until: xl) {

        }
        @include mq($until: lg) {
            width: 21vw;

        }
        @include mq($until: md) {
            width: 21vw;
            margin-top: calc(100vw - 80vw);
            padding-top: 0vw;
        }
        @include mq($until: sm) {
            margin-top: calc(100vw - 81vw);
            width: 28vw;
            padding-top: 21px;
        }   
        @include mq($until: xs) {
            margin-top: calc(100vw - 81vw);
            width: 28vw;
            padding-top: 28px;
            width: 44vw;
        }
        span, a{
            font-size: rem(28px);
            @include mq($until: lg) {
                font-size: rem(19px);
            }
            color: #fff;
            .part2{
                padding-left: 2vw;
            }
        }
    }
    &__hotress{
        // height: 8vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .hotresChooser {
            margin-top: 13px;
        }
    }
    &__head{
             display: flex;
        flex-direction: column;
        align-items: center;
        @include mq($until: xs) {
            display: none;
        }
    }
}

.aparmtents{
    margin-bottom: 13vw;
    @include mq($until: xl) {
        margin-bottom:0;   
    }
    &__site-tittle2{
        color: $color-main;
        font-size: 3vw;
        padding-top: 5vw;
        padding-bottom: 2vw;
        font-family: 'Garamond';
        line-height: .9 ;
        text-align: center;
        @include mq($until: lg) {
            padding-bottom: 5vw;
        }
        @include mq($until: sm) {
            font-size: rem(30px);
        }
    }
    .apartment-list{
        display: flex;
        justify-content: space-between;
        margin-bottom: 10vw;
        width: 100%;
        // max-width: 98vw;
        align-items: self-start;
        @include mq($until: lg) {
            margin-bottom: 5vw;
        }
        @include mq($until: lg) {
            flex-wrap: wrap;
            max-width:100%;
            margin-bottom: 13vw;
        }
        @include mq($until: md) {
            flex-wrap: wrap;
            max-width:100%;
            margin-bottom: 10vw;
        }
        @include mq($until: sm) {
            margin-bottom: 0;
        }
    }
    &__sublink{
        cursor: pointer;
        font-size: rem(21px);
        transition: all .4s ease-in-out;
        color: $color-extra;
          font-style: italic;
          font-weight: 600;
          margin-right: 0;
          @include mq($until: xl) {
            font-size: rem(16px);
          }
        hr{
            background-color: $color-extra;
            height: 2px;
            
            transition: all .4s ease-in-out;
            @include mq($until: xl) {
                margin:15px 0;
            }
        }
        &:hover {
            color: $color-extra;
            hr{
             width: 7.7vw;
          
            }
         }    
    } 
  }

  .icons{
      margin: 0 8vw;
      display: flex;
      flex-direction: column;
      padding-bottom: 8vw;
      @include mq($until: xl) {
        margin: 0 0;
      }
      &__head{
        font-size: 2.5vw;
        padding-bottom: 8vw;
        color: $color-main;
        @include mq($until: md) {
            font-size: rem(21px);
        }

      }
      &__wrapper{
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: baseline;
          padding-bottom: 3vw;
          @include mq($until: xs) {
            justify-content: space-around;
          }

      }
      &__item{  
         width: 5.5vw;
         min-width: 55px;
         margin:13px 8px;
        fill:  $color-extra;

        @include mq($until: xs) {
            margin:15px 13px;
            width: 22%;
          }
      }

      &__info{
        display: flex;
        @include mq($until: sm) {
            max-width: 96%;
          }
        @include mq($until: xs) {
            max-width: 100%;
            text-align: center;
          }
      }
      &__text-bar{
          width: 5px;
          background-color: $color-extra;
          height: auto;
          margin-right: 2vw;
          margin-left: 3vw;
          @include mq($until: md) {
            margin-left: 4vw;
          }
          @include mq($until: sm) {
            margin-left: 6vw;
            margin-right: 5vw;
          }
          @include mq($until: xs) {
           display: none;
        }
      }
      &__text{
          padding-top: 2vw;
      }
  }

  .quality{
    padding-top: 8vw;
    padding-bottom: 8vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $color-main;
    height: auto;
   justify-content: center;
   margin-bottom: 5vw;
   margin-top: 5vw;

//    @include mq($until: xxxl) {
//     height: 120vh;
//    }
   @include mq($until: lg) {
        padding-top: 14vw;

   }
   @include mq($until: sm) {
       padding-top: 0;
   }
    &__wrapper{
        display: flex;
       justify-content: space-between;
       width: 100%;
       align-items: center;
       @include mq($until: sm) {
        flex-direction: column-reverse;
        text-align: center;
      }
    }
    &__description{
        max-width: 34vw;
        margin-left: 5vw;
        @include mq($until: xl) {
            margin-left: 0;
            max-width: 38vw;
        }
        @include mq($until: lg) {
            min-width: 44vw;
            margin-left: 0;
        }
        @include mq($until: sm) {
            min-width: 82vw;
            margin-left: 0;
            z-index: 10;
        }
        h1, h2, h3, h4{
            color:$color-extra;
            padding-bottom: 2vw;
            @include mq($until: xl) {
                font-size: rem(26px);
            }
            @include mq($until: lg) {
                font-size: rem(24px);
            }
            @include mq($until: sm) {
                font-size: rem(22px);
            }
        }
        p{
            color:$color-main-light;
        }
        .button{
            margin:3vw 0 0 3vw;
            @include mq($until: xl) {
                font-size: rem(16px);
            }
            @include mq($until: lg) {
                margin:3.4vw 0 0 0;
            } 
        }
    }
    &__picture{
        width: 33.9vw;
        height: 33.9vw;
        position: relative;
        @include mq($until: sm) {
          margin-bottom: 5vw;
        }
        @include mq($until: sm) {
            width: 100vw;
            height: 60vw;
        }
    }
    &__mask{
        position: absolute;
        z-index: 3;
        width: 34.2vw;
        height: 34.2vw;
        svg{
            fill: $color-main; 
        }
        @include mq($until: sm) {
            display: none;
        }
    }
    &__paralax-item{
        overflow: hidden;
        position: absolute;
        width: 34vw;
        height: 34vw; 
        @include mq($until: sm) {
            width: 100vw;
            height:55vw;
        }
        video {
            width: 68vw;
            overflow: hidden;
            object-position: -20vw;
            opacity: .8;
            @include mq($until: sm) {
                width: 120vw;
            }
          }

    }
    &__socials{
        padding-top: 5vw;
        display: flex;
        align-items: center;
        width: 59vw;
        @include mq($until: lg) {
            width: 66.5vw;
        }
        @include mq($until: sm) {
            width: 40.5vw;
        }
        @include mq($until: xs) {
            width: 66.5vw;
        }
        hr{
            background-color: $color-extra;
            @include mq($until: sm) {
                opacity: 0;
            }
        }
      span{
           min-width: fit-content;
            margin-right: 2vw;
            color: #fff;
            opacity: .3;
        }
    }

    &__links{
        display: flex;
        margin: 2vw;
        a{
            opacity: .3;
            padding: 8px;
            transition: all .4s ease-in-out;
            svg{
                fill: #fff;
                @include mq($until: lg) {
                   width: 24px;
                }
            }
            &:hover{
                opacity: 1;
            }
        }
    }

  }

  .about-place{
      padding-top: 5vw;
      padding-bottom: 5vw;
      margin-bottom: 13vw;
      position: relative;
      display: flex;
      @include mq($until: md) {
        position: static;
        padding-top:0 ;
      }
      justify-content: space-between;
      &__wrapper{
          display: flex;
          padding-top: 8vw;
          @include mq($until: md) {
            flex-direction: column;
          }
      }
      &__picture-part{
          position: relative;
      }
      &__slogan{
        position: absolute;
        z-index: 2;
        bottom: 36%;
        max-width: 80%;
        left: 8vw;
        @include mq($until: lg) {
            bottom: 30%;
            left: 13%;
        }
        @include mq($until: md) {
            bottom: 10%;
            left: 0;
        }
        h2{
            font-size: 4.5vw;
            padding-bottom: 1vw;
            font-family: 'Garamond';
            line-height: .9;
            color: $color-main;
            font-weight: 600;
            padding-bottom: 0;
            margin-bottom: 0;
            margin-top: 0;
            @include mq($until: md) {
                font-size: 6vw;
            }
            @include mq($until: xs) {
                font-size: 6.5vw;
            }

        }
        a{
            font-size: 1.3vw;
            font-weight: 550;
            margin-left: 4.8vw;
            @include mq($until: sm) {
                font-size: 2vw;
                text-align: center;
            }
            @include mq($until: xs) {
                font-size: 3vw;
            }
        }
      }
      &__picture{
          width: 55vw;
          @include mq($until: md) {
            width: 50vw;
            height: 34vw;
            overflow: hidden;
            object-position: center;
            margin-left: auto;
          }
      }
      &__info-part{
        padding-left: 5vw;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        @include mq($until: md) {
            position: static;
            align-items: center;
        }
        h3{
            padding-bottom: 1.8vw;
            color: $color-extra;
            @include mq($until: xl) {
                padding-bottom: 1vw;
            }
        }
      }
      &__box{
        display: flex;
        flex-direction: column;
        margin-right: auto;
        @include mq($until: md) {
            flex-direction: row;
            margin-left: auto;
            min-width: 70%;
            justify-content: space-between;
            margin-right: 5vw;
            align-items: center;
        }
        @include mq($until: xs) {
            width: 90%;
            flex-direction: column;
        }
      }
      &__top-text{
        h1, h2, h3, h4{

            @include mq($until: lg) {
                font-size: rem(24px);
            }
            @include mq($until: sm) {
                font-size: rem(22px);
            }
        }
        margin-right: 5vw;
        @include mq($until: xl) {
            margin-right: 1vw;
        }
        @include mq($until: md) {
            width: 90%;
            margin-top: 5vw;
            margin-right: 0;
            padding-right: 0;
        }
    }
      &__links{
          h4{
                cursor: pointer;
                font-size: rem(21px);
                transition: all .4s ease-in-out;
                color: $color-main;
                font-style: italic;
                    @include mq($until: xxl) {
                        font-size: rem(16px);
                    }
            hr{
                background-color:$color-main-light;
                transition: all .4s ease-in-out;
                height: 2px;
                margin-top: 13px;
                margin-bottom: 1.8vw;
                width: 20%;
                    @include mq($until: xl) {
                        margin-bottom: 1vw;
                    }
            }
            &:hover {
                color: $color-extra;
                    hr{
                    background-color:$color-main-light;
                    width: 100%;
                    }
            }
          }

      }
      &__color{
          position: absolute;
          z-index: -1;
          background-color:$color-main-light;
          height: 96%;
          width: 60vw;
          margin-top: 5vw;
          margin-bottom: 5vw;
          top: 0;
          right: 0;

          @include mq($until: md) {
            z-index: 0;
            background-color: #f4f7fc;
            height: 50%;
            width: 100vw;
            margin-bottom:0;
            top: unset;
            right: 0;
            bottom: 0;
            display: none;
          }
          
      }
    &__sublink{
        cursor: pointer;
        font-size: rem(21px);
        transition: all .4s ease-in-out;
        color: $color-extra;
          font-style: italic;
          font-weight: 600;
          margin-right: 0;
          @include mq($until: xl) {
            font-size: rem(16px);
          }
          @include mq($until: xs) {
            width: 100%;
            padding-top: 13px;
          }
        hr{
            background-color: $color-extra;
            height: 2px;
            
            transition: all .4s ease-in-out;
            @include mq($until: xl) {
                margin:15px 0;
            }
        }
        &:hover {
            color: $color-extra;
            hr{
             width: 7.7vw;
          
            }
         }    
    }
}


p{
    @include mq($until: xxl) {
      font-size: rem(15px); 
    }
    @include mq($until: lg) {
        line-height: 1.8;
    }
}