.hotresChooser {
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  margin-right: 3vw;
  padding: 0 2vw 2vw 2vw;
  width: 100%;
  @include mq($until: md) {
    padding: 0 2vw 5px 2vw;
    width: unset;
  }
}
.inpt-wrapper{
  
  &:hover{
    hr{
      background-color: $color-extra;
    }
  }
 hr {
    width: 100%;
    height: 1px;
    background-color: #fff;
    margin: 0 0;
    transition: all .4s ease-in-out;
  }
}

.hotresChooser:after {
  content: '';
  display: block;
  clear: both;
}

.hotresChooser div {
  float: left;
  margin: 0;
  box-sizing: border-box;
  padding: 4px;
}

.hotresChooser div input, .hotresChooser div select {
  border: none;
  background-image: none;
  background-color: unset;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  cursor: pointer;
  color: #fff;
  font-size: 15px;
  height: 40px;
  line-height: 40px;
  &::placeholder {
    color: #fff;
    letter-spacing: 0.3px;
    font-weight: 400;
    font-size: 15px;
    width: 100%;
    font-style: italic;
  }
}

.hotresChooser div select {
  padding-left: 10px;
  padding-right: 10px;
  
}


.hotresInputSelected {
  // background: #fff !important;
  // color: #ff5000 !important;
  border: unset;
  
}


.hotresChooser div button {
  border: none;
  background: $color-main;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
  color: white;
  text-align: center;
  box-sizing: border-box;
  height: 40px;
  line-height: 40px;
  letter-spacing: 0.3px;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  margin-top: 1vw;
  transition: all 0.4s ease-in-out;

}


.hotresChooser div button:hover {
  background: $color-extra;
  color: #fff;
}


/*smartphone*/
@media only screen and (max-width: 40em) {
  .hotresChooser div {
    width: 100%
  }

  .hotresChooser div:last-child {
    width: 100%;
  }
}


/*datepicker styles*/


//----UI-CALENDAR----//

.ui-datepicker-month, .ui-datepicker-year {
  text-transform: uppercase;
  font-weight: normal;
  

}

.ui-datepicker {
  // background: #fff;
  color: #000;
  padding: 10px;
  font-family: Arial;

}

.ui-datepicker * {
  font-size: 15px;
}

.ui-state-disabled {
  color: #ccc !important;
  font-weight: normal !important;
}

.ui-datepicker-prev {
  float: left;

}

.ui-datepicker-prev::before {
  color: #000;
  font-size: 20px;
  content: '\2039';
  background: #eee;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  line-height: 30px;
  cursor: pointer;
  font-weight: bold;
  display: block;
}


.ui-datepicker-next::before {
  color: #000;
  font-size: 20px;
  content: '\203A';
  background: #eee;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  line-height: 30px;
  cursor: pointer;
  font-weight: bold;
  display: block;
}

.ui-datepicker .ui-datepicker-next {

  float: right;
}

.ui-icon {
  display: none;
}

.ui-datepicker .ui-datepicker-title {
  font-size: 15px;
  font-weight: bold;
  height: 30px;
  line-height: 30px;
}

.dp-highlight {
  background: #404040;
  color: #000;
}

.ui-datepicker a,
.ui-datepicker a:hover {
  text-decoration: none;

}

.ui-datepicker a:hover,
.ui-datepicker td:hover a {
  color: #e57c13;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.ui-datepicker .ui-datepicker-header {
  margin-bottom: 4px;
  text-align: center;
}

.ui-datepicker .ui-datepicker-prev:hover, .ui-datepicker .ui-datepicker-next:hover {
  color: #3d95cc;
}

.ui-datepicker .ui-icon {

}

.ui-datepicker .ui-datepicker-calendar {
  table-layout: fixed;
  max-width: 400px;
}

.ui-datepicker .ui-datepicker-calendar th,
.ui-datepicker .ui-datepicker-calendar td {
  text-align: center;
  padding: 10px 10px;
  color: #000;
  border-left: 1px solid #eee;


}


.ui-datepicker .ui-datepicker-calendar th {
  color: #404040;
  opacity: .7;
  font-weight: bold;
}


.ui-datepicker-current-day a {
  color: #fff !important;
}

.ui-datepicker .ui-datepicker-calendar td:hover {
  background: #ff8000;
  cursor: pointer;
}

.ui-datepicker .ui-datepicker-calendar td a {
  text-decoration: none;
  color: #303030;
  font-weight: bold;
}

.ui-datepicker .ui-datepicker-calendar td:hover a {
  color: #fff;

}

.ui-widget-content {
  display: none;
  margin-left: 0px;
  // box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.2);
  z-index: 9999999 !important;
  background-color: #fff;
}

.ui-datepicker .ui-datepicker-current-day {
  background: #ff5000;
}

.ui-datepicker .ui-datepicker-current-day a {
  color: #fff;
}

.ui-datepicker .ui-datepicker-calendar .ui-datepicker-unselectable:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color:unset;
  cursor: default;

}

.between-date {
  background: red !important
}

input:focus {
  outline: unset;
}