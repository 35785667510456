.apartment-card{
    display: block;
    display: flex;
    border-radius: 5px;
    position: relative;
    @include mq($until: lg) {
      width: 100%;
    }
        &__wrapper{
            display: flex;
            position: relative;
            z-index: 20;
            width: 55vw;
            align-items: flex-end;
            transition: all .4s ease-in-out;

            @include mq($until: lg) {
              align-items: flex-start;
              width: 100vw;
              min-width: 450px;
              padding-bottom: 10vw;
              justify-content: space-between;
              height: fit-content;
            }

            @include mq($until: sm) {
              flex-direction: column;
              width: 100vw;
            }
            &:hover{
              width: 68vw;
              @include mq($until: lg) {
                width: 85vw;
              }
              @include mq($until: sm) {
                width: 100vw;
              }
            }
            position: relative;
            
            &:hover  .apartment-card__image .secondhead {
              opacity: 0;
            }
            &:hover .apartment-card__color {
              right: 5vw;
              width: 38vw;
              height: 43vw;
              @include mq($until: xxl) {
                bottom:-5vw;
                right: 3vw;
              }
              @include mq($until: lg) {
                right: -5vw;
                bottom: 6vw;
                width: 76vw;
                height: 36vw;
              }
              // @include mq($until: sm) {
              //   height: 41vw;
              // }
              @include mq($until: sm) {
                width: 100vw;
                right: 0;
                bottom: 5vw;
              }
            }
            &:hover .apartment-card__image {
              width:34vw;
              @include mq($until: lg) {
                width: 28vw;
                height: 28vw;
              }
              @include mq($until: sm) {
                width: 90vw;
                height: 45vw;
              }
            }
            &:hover .apartment-card__content  {
              opacity: 1;
              visibility: visible;
              padding: 10vw 3vw 3vw 3vw;
              @include mq($until: lg) {
                padding: 3vw 3vw 3vw 3vw;
              }
              @include mq($until: sm) {
                min-width: 100vw;
                height: auto;
                align-items: flex-end;
                visibility: visible;
                opacity: 1;
                right: 0;
                padding: 3vw 8vw 13vw 10vw;
              }
            }
        }
        // img
        &__image{
          width:24vw;
            height: 34vw;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            position: relative;
            z-index: 5;
            top: 0;
            padding: 3vw;
            transition: all .4s ease-in-out;
           margin: 5vw 0 0 0;
           @include mq($until: xl) {
            width: 39vw;
            height: 32vw;
          }
          @include mq($until: lg) {
            width: 50vw;
            margin: 0 0 0 0;
          }
          // @include mq($until: sm) {
          //   width: 70vw;
          //   margin: 0 0 0 0;
          // }
          @include mq($until: sm) {
            width: 90vw;
            height: 50vw;
          }
          .secondhead{
            opacity: 1;
            transition: all .4s ease-in-out;
            width:  100%;
            height: 100%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 2vw;
            min-width: 50%;
            text-align: right;
            font-weight: 600;
            color: #fff;
            line-height: 1;
            text-shadow: 0px 0px 34px rgba(22, 16, 59, 0.521);
            @include mq($until: lg) {
              font-size: 4vw ;

            }
            @include mq($until: sm) {
              display: none;
            }
          }
        }
        // color bg
        &__color{
            background-color: $color-main-light;
            z-index: 0;
            position: absolute;
            height: 23vw;
            width: 35vw;
            right: -28vw;
            bottom:-5vw;
            transition: all .4s ease-in-out;
            @include mq($until: xxl) {
              height: 43vw;
              bottom:-13vw;
            }
            @include mq($until: xl) {
              width: 38vw;
              height: 43vw;
              bottom: -18vw;
              right: -33vw;
            }
            @include mq($until: lg) {
              right: 12vw;
              bottom: -36vw;
              width: 76vw;
              height: 36vw;
            }
            @include mq($until: md) {
              bottom: -36vw;
            }
            @include mq($until: sm) {
              width: 100vw;
              right: 0;
              bottom: -66vw;
            }
        }
        // Content
        &__content{
          padding: rem(45px) rem(25px);       
          width: 25vw;
          height: 100%;
          padding: 3vw 3vw 3vw 3vw;
          position: relative;
          z-index: 3;
          visibility: hidden;
          opacity: 0;
          transition: all .4s ease-in-out;
          @include mq($until: xl) {
            width: 28vw;
          }
          @include mq($until: lg) {
            width: 50vw;
            padding-right: 7vw;
            height: 50%;        
          }
          // @include mq($until: sm) {
          //   width: 70vw;
          // }
          @include mq($until: sm) {
            min-width: 100vw;
            height: auto;
            align-items: flex-end;
            visibility: visible;
            opacity: 1;
            right: 0;
            padding: 3vw 8vw 5vw 10vw;
          }

          .sublink{
           margin-top: 5vw;
            cursor: pointer;
            font-size: rem(21px);
            transition: all .4s ease-in-out;
            color: $color-extra;
              font-style: italic;
              font-weight: 600;
              margin-right: 0;
              @include mq($until: xl) {
                font-size: rem(16px);
              }
            hr{
                background-color: $color-extra;
                height: 2px;
                
                transition: all .4s ease-in-out;
                @include mq($until: xl) {
                    margin:15px 0;
                }
            }
            &:hover {
                color: $color-extra;
                hr{
                 width: 7.7vw;
              
                }
             }    
          }
        }
        &__badge{
          position: absolute;
          top: rem(-20px);
          right: 0;
 
          font-size: rem(15px);
          font-weight: $fw-black;
          width: rem(140px);
          height: rem(40px);
          display: flex;
          justify-content: center;
          align-items: center;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          color: #fff !important;
        }
        &__header{
          font-size: rem(20px);
          color: $color-main;
          font-weight: $fw-black;
          margin-bottom: rem(25px);
          @include mq($until: sm) {
            margin-bottom: rem(15px);
            font-size: rem(18px);
          }
        }
        &__description{
          color: $color-main;
          font-weight: $fw-regular;
          font-size: rem(15px);
          line-height: 1.8;
          @include mq($until: xl) {
            font-size: rem(14px); 
          }
        }
        &__clicker{
          padding-top: 2vw;
          span{
          padding: 21px 34px 21px 0;
          font-style: italic;
          font-size: rem(14px);
          color: $color-extra;
          font-weight: normal;
          transition: all .4s ease-in-out;
            &:hover{
              color: $color-main;
            }
            &:hover hr{
              width: 5vw;
            }
            hr{
              background-color:  $color-extra;
              margin-top: 0;
              margin-bottom: 13px;
              height: 1px;
              transition: all .4s ease-in-out;
            }
          }
          @include mq($until: lg) {
            padding: 21px 0 13px 0;
            hr{
              margin-top: 13px;
            }
          }
          @include mq($until: md) {
            display: none;
          }

        }
        &:nth-child(even){
            display: inline-block;

            .apartment-card__wrapper{
                flex-direction: column;
                width: 32vw;
                align-items: flex-start;
                @include mq($until: lg) {
                  width: 100vw;
                  margin-left: auto;
                }
                @include mq($until: sm) {
                  align-items: flex-start !important;
                }

                &:hover{
                  width: 45vw;
                  @include mq($until: lg) {
                    width: 85vw;
                    display: flex;
                    // top: -4VW;
                  }
                  @include mq($until: sm) {
                    width: 100vw;
                  }

                }
                &:hover .apartment-card__image {
                  width: 45vw;
                  @include mq($until: lg) {
                    width: 28vw;
                    height: 28vw;
                  }
                  @include mq($until: sm) {
                    width: 90vw;
                    height: 45vw;
                  } 
                    
                }
                &:hover .apartment-card__content{
                  @include mq($until: lg) {
                    padding: 7vw 3vw 3vw 3vw;
                  } 
                  // @include mq($until: md) {
                  //   padding: 7vw 3vw 3vw 3vw;
                  // } 
                  @include mq($until: sm) {
                    min-width: 100vw;
                    height: auto;
                    visibility: visible;
                    opacity: 1;
                    right: 0;
                    padding: 3vw 8vw 5vw 10vw;
                  }
                }
                @include mq($until: lg) {
                  position: relative; 
                  align-items: flex-end;
                  width: 100vw;
                  flex-direction: row-reverse;
                  width: 100vw;
                }
                @include mq($until: sm) {
                  flex-direction: column;
                }
            }
            .apartment-card__color{
               display: none;
               right: -30vw;
              
            }
            .apartment-card__image{
              width: 32vw;
               height: 21vw;
               transition: all .4s ease-in-out;
               @include mq($until: xl) {
                width: 30vw;
                justify-content: flex-end;
              }
              @include mq($until: lg) {
                width: 50vw;
                height: 32vw;
              }
              // @include mq($until: sm) {
              //   width: 70vw;
              //   margin: 0 0 0 0;
              // }
              @include mq($until: sm) {
                width: 90vw;
                height: 50vw;
                margin-left: 10vw;
              }
              .secondhead{
                text-align: left;
              }
              
            }
            .apartment-card__content{
               padding: 3vw 3vw 3vw 3vw;
               @include mq($until: lg) {
                width: 50vw;
                height: 32vw;
                text-align: right;
              }
              // @include mq($until: sm) {
              //   width: 70vw;
              // }
              @include mq($until: sm) {
                min-width: 100vw;
                height: auto;
                align-items: flex-end;
                visibility: visible;
                opacity: 1;
                right: 0;
                padding: 3vw 8vw 10vw 10vw;
              }
            }
        }    
}

// p{
//   @include mq($until: xl) {
//       font-size: rem(13px);
//       line-height: 1.8;
//   }
// }