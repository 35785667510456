.apartment{
  &__description{
    h2{
      font-size: rem(18px);
    }
  }
  
  &__slider-wrapper{
    position: relative;
      background-color:$color-main;
  }
  &__slider{
    max-height: 100vh;
    overflow: hidden;
    height: auto;

    @include mq($until: lg) {
      max-height: unset;
      overflow: initial;
    }
  }
  &__strip{
    position: absolute;
    bottom: -1px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-main;
    min-height:3vw;
    padding-top: 10px;
    padding-bottom: 10px;

    @include mq($until: md) {
      background-color: $color-main;
    }
    @include mq($until: sm) {
      background-color: $color-main;
      flex-wrap: wrap;
      bottom: 40px;
    }
    @include mq($until: xs) {
      bottom: 60px;
    }

  }
  &__header{
    color: $color-font-light;
    margin-bottom: 0;
    font-size: rem(26px);
    font-weight: $fw-medium;
    max-width: 80%;
    @include mq($until: sm) {
      font-size: rem(24px);
    }
    @include mq($until: xs) {
      font-size: rem(26px);
      min-width: fit-content;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
  }
  &__properties{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
    @include mq($until: xs) {
      margin-right: auto;
    }
  }
  &__property{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 20px;
    @include mq($until: xs) {
      margin-left: 13px;
      margin-top: 8px;
      &:first-of-type{
        margin-left: 0;
      }
    }
    img{
      max-height: 25px;
      width: auto;
    }
    span{
      font-size: rem(16px);
      font-weight: $fw-semi-bold;
      color: $color-font-light;
      margin-left: 10px;
      @include mq($until: xs) {
        margin-left: 5px;
      }
    }
  }
  &__city{
    color: $color-font-light;
    margin-left: 20px;
    font-weight: $fw-light;
    font-size: rem(13px);
    @include mq($until: xs) {
      margin-left: 10px;
    }

    b{
      text-transform: capitalize;
      font-size: rem(16px);
      font-style: italic;
    }
  }
  &__box{
    position: absolute;
    // bottom: 150px;
    top: calc(100vw - 82vw);
    left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: row-reverse;
    flex-direction: column-reverse;
    @include mq($until: xxl) {
      top: calc(100vw - 84vw);
    }
    @include mq($until: lg) {
      top: calc(100vw - 85vw);
    }
    @include mq($until: md) {
      position: relative;
      flex-direction: row-reverse;
      align-items: center;
      padding: unset;
      margin: unset;
      width: 100%;
      background-color: $color-main;
      top: calc(100vw - 95vw);
    }
  }
  &__arrows{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    padding-top: 2vw;
    width: 100%;
    @include mq($until: lg) {
      padding-top: 1vw;
    }
    @include mq($until: md) {
      justify-content: flex-end;
      margin-bottom: 8px;
      width: 22%;
      padding-right: 4vw;
    }
    @include mq($until: sm) {
      width: 28%;
    }
    @include mq($until: xs) {
      display: none;
    }
  }
  &__price-wrapper{
    background-color: $color-main-transapernt;
    padding: 15px;
    border-radius: 0 0 5px 5px;
    width: 18vw;
    @include mq($until: lg) {
      width: 21vw;
    }

    @include mq($until: md) {
      bottom: 100px;
      top: calc(100vw - 85vw);
      padding: 0 15px 15px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 0;
      padding: 0;
      background-color:unset;
      width: 100%;
      padding-top: 10px;
    }

    @include mq($until: sm) {
      padding: 10px 0;
      justify-content: flex-start;
      padding-top: 0;
    }
    @include mq($until: xs) {
     flex-wrap: wrap;
     justify-content: center;
     align-items: center;
    }

    .hotresChooser{
      .button{
        width: 100%;
        font-size: 1vw;
        @include mq($until: xl) {
          font-size: 1.3vw;
        }
        @include mq($until: lg) {
          font-size: 1.3vw;
        }
        @include mq($until: md) {
            min-width: 170px;
            font-size: 1.5vw;
        }
        @include mq($until: sm) {
          font-size: 2vw;
          min-width: 130px;
          height: 40px;
        }
        @include mq($until: xs) {
          font-size: 3.3vw; 
          display: inline-block;
          width: auto;
          min-width: 210px;
        }
        
      }

    }

  }
  &__price{
    margin-bottom: 15px;
    font-display: flex;

    @include mq($until: md) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0;
      width: 100%;
    }
    @include mq($until: md) {
      margin-bottom: 0;

    }
    @include mq($until: sm) {
      width: auto;
      min-width: 290px;
      margin-bottom: 5px;
      min-width: fit-content;
    }
    @include mq($until: xs) {
      width: auto;
      min-width: fit-content;
      margin-bottom: 8px;
    }
    p{
      font-style: italic;
      color: $color-main;
      
    }
    p:first-of-type{
      font-weight: $fw-semi-bold;
      margin-bottom: 0;
      font-size: rem(24px);
      color: $color-extra;
      padding-left: 2vw;
      @include mq($until: xxl) {
        font-size: rem(21px);
        padding-left: 4vw;
      }
      @include mq($until: xl) {
        font-size: rem(18px);
      }
      
    }
    p:last-of-type{
      font-size: rem(18px);
      font-weight: $fw-light;
      text-align: center;
      color: #fff;
      @include mq($until: xxl) {
        font-size: rem(16px);
      }
      @include mq($until: xl) {
        font-size: rem(14px);
      }
      @include mq($until: md) {
        margin-left: 5px;
        margin-right: 21px;
      }
      @include mq($until: xs) {
        font-size: rem(14px);
        margin-right: 13px;
      }
    }
  }

  &__content{
    display: grid;
    grid-template-columns: 1.25fr 1fr;
    grid-gap: 5vw;
    margin-top: 5vw;
    margin-bottom: 5vw;
    @include mq($until: lg) {
      margin-top: 75px;
    }
    @include mq($until: md) {
      margin-top: 90px;
      grid-template-columns: 1fr;
      grid-gap: 50px;
    }
    @include mq($until: xs) {
      grid-gap: 30px;

    }
  }

  &__amenities{
    h2{
      margin-bottom: 50px;
      @include mq($until: xl) {
        font-size: rem(18px);
      }
      @include mq($until: md) {
        margin-bottom: 30px;
        font-size: rem(16px);
      }
    }
  }

  &__map{
    min-height: 500px;
    margin-top: 100px;
    @include mq($until: md) {
      min-height: 400px;
    }
    @include mq($until: sm) {
      min-height: 300px;
      margin-top: 50px;
    }
    @include mq($until: xs) {
      margin-top: 30px;
    }
  }
}
.arrow-slide {
  width: 3vw;
  height: 3vw;
  cursor: pointer;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  transition: background-color .3s ease-in-out;
  border-radius: 50%;
  @include mq($until: md) {
    background-color:$color-main-light;
  }
  @include mq($until: sm) {
    height: 34px;
    width: 34px;
  }
  &:hover{
    background-color: $color-extra;
  }
}
.arrow-slide--prev {
  background-image: url(../../images/arrow-slider.png);
  right: 50px;
}
.arrow-slide--next {
  background-image: url(../../images/arrow-slider.png);
  transform: rotate(180deg);
  margin-left: 15px;
}
.amenity__name{
  font-size: rem(16px);
}




.slick-slide img {
  display: block;
  height: 44vw;
  @include mq($until: md) {
    height: 55vw;
  }
  // @include mq($until: sm) {
  //   height: 75vw;
  // }
  @include mq($until: xs) {
    height:60vw;
  }
}